.quanturama__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 3rem;
  }
  
  .quanturama__navbar-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
  }
  
  .quanturama__navbar-links_logo {
    display: flex; /* Add this line to make it a flex container */
    align-items: center; /* Align the logo and text vertically */
    margin-right: auto; /* Add this line to push the logo to the left */
  }
  
  .quanturama__navbar-links_logo img {
    width: 210px;
    height: 75px;
    vertical-align: middle;
  }
  
  .quanturama__navbar-links_logo span {
    /* CSS styles for the text "Quanturama" */
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 28px;
    line-height: 12px;
    text-transform: capitalize;
    margin-left: 5px;
    /* Adjust the margin as needed */
  }
  
  .quanturama__navbar-links_container {
    display: flex;
    flex-direction: row;
  }
  .quanturama__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
  
  .quanturama__navbar-links_container p,
  .quanturama__navbar-sign p,
  .quanturama__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.22px;
    text-transform: capitalize;
    margin: 0rem 1rem;
    cursor: pointer;
  }
  .quanturama__navbar-sign button,
  .quanturama__navbar-menu_container button {
      padding: 0.5rem 1rem;
      color: #fff;
      background: #FF4820;
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      border: none;
      outline: none;
      cursor: pointer;
      border-radius: 5px;
  }

  .quanturama__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}
.quanturama__navbar-menu svg {
    cursor: pointer;
}

.quanturama__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.quanturama__navbar-menu_container p {
    margin: 1rem 0;
}

.quanturama__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .quanturama__navbar-links_container {
        display: none;
    }

    .quanturama__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .quanturama__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .quanturama__navbar {
        padding: 2rem;
    }

    .quanturama__navbar-sign {
        display: none;
    }

    .quanturama__navbar-menu_container {
        top: 20px;
    }

    .quanturama__navbar-menu_container-links-sign {
        display: block;
    }
}