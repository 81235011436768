.quanturama__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar)
}

.quanturama__cta-content {
    display: flex;
    flex-direction: column;

    text-align: left;
    color: #fff;
}

.quanturama__cta-content p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
}

.quanturama__cta-content h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 45px;
    font-weight: 800;
}

.quanturama__cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 2rem;
}

.quanturama__cta-btn button {
    background: #000;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border-radius: 2rem;

    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}

.swirl-out-bck {
	-webkit-animation: swirl-out-bck 0.6s ease-in both;
	        animation: swirl-out-bck 0.6s ease-in both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-2 9:45:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swirl-out-bck
 * ----------------------------------------
 */
 @-webkit-keyframes swirl-out-bck {
    0% {
      -webkit-transform: rotate(0) scale(1);
              transform: rotate(0) scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(-540deg) scale(0);
              transform: rotate(-540deg) scale(0);
      opacity: 0;
    }
  }
  @keyframes swirl-out-bck {
    0% {
      -webkit-transform: rotate(0) scale(1);
              transform: rotate(0) scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(-540deg) scale(0);
              transform: rotate(-540deg) scale(0);
      opacity: 0;
    }
  }
  

@media screen and (max-width: 650px) {
    .quanturama__cta {
        flex-direction: column;
    }

    .quanturama__cta-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .quanturama__cta {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .quanturama__cta-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .quanturama__cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}
