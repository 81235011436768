.quanturama__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.quanturama__brand div {
    flex: 1;
    max-width: 75px;
    min-width: 60px;


    display: flex;
    justify-content: center;
    align-items: center;
}
  
  .quanturama__brand img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Adjust the object-fit property as needed */
  }
  